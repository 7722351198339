import axios from "axios";
import axiosConfig from "../Application/Services/axios.js";

const teamClient = axios.create({
    baseURL: '/api/v1/'
});

// Add the current team ID to all requests.
teamClient.interceptors.request.use(...axiosConfig.teamIdInterceptor);

export default {
    /**
     * Generate and test the uniqueness of a slug on the server.
     * @param {string} table
     * @param {string} slug
     * @returns {Promise<>}
     */
    generateSlug(table, slug) {
        return teamClient.get(`teams/slug`, {
            params: {
                ctx: table,
                slug: slug
            }
        });
    },
    /**
     * Fetch all teams.
     * @returns {Promise<array|null>}
     */
    getTeams: () => teamClient.get('teams'),
    /**
     * Fetch a specific team from the server.
     * @param {number} id
     * @returns {Promise<object>}
     */
    getTeam: id => teamClient.get(`teams/${id}`),
    /**
     * Store the given data as a team on the server.
     * @param {object} attr
     * @returns {Promise<object>}
     */
    storeTeam: attr => teamClient.post(`teams`, attr),
    /**
     * Update the given team.
     * @param {number} id
     * @param {object} attr
     * @returns {Promise<object>}
     */
    updateTeam: (id, attr) => teamClient.put(`teams/${id}`, attr),
    /**
     * Delete a team.
     * @returns {Promise<>}
     */
    deleteTeam: id => teamClient.delete(`teams/${id}`),
};
