import PlanClient from "../planClient.js";
import debounce from "lodash/debounce";

const debouncedFn = debounce(_sendRequest, 500);

export default {
    data() {
        return {
            slugServerError: true,
        }
    },
    methods: {
        checkSlugUniqueness(event, table) {
            debouncedFn(event, table, this);
        },
    },
}

function _sendRequest(event, table, ctx) {
    PlanClient.generateSlug(table, event.target.value)
        .then((response) => {
            ctx.attr.slug = response.data;
            ctx.slugServerError = true;
        })
        .catch((error) => {
            ctx.slugServerError = error.response.data.errors.slug.shift()
        });
}
