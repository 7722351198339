import axios from "axios";
import axiosConfig from "../Application/Services/axios.js";

const sharedClient = axios.create({
    baseURL: '/api/v1/shared/'
});

// Add the current team ID to all plan requests.
sharedClient.interceptors.request.use(...axiosConfig.teamIdInterceptor);

export default {
    fetchLanguages() {
        return sharedClient.get('languages')
            .then(response => response.data);
    }
};
