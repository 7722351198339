import guest from "./guest.js";
import auth from "./auth.js";
import permission from "./permission.js";
import subscribed from "./subscribed.js";

const middlewares = {
    guest,
    auth,
    permission,
    subscribed
}

/** Run all defined middleware for a route recursively. */
export default function middlewarePipeline(context, middleware, index =  0) {
    const resolvedMiddleware = resolveMiddleware(middleware, index);

    if (!resolvedMiddleware) {
        return context.next;
    }

    const fn = () => {
        resolvedMiddleware.fn({
            ...context,
            next: middlewarePipeline(context, middleware, index + 1),
        }, resolvedMiddleware.arguments);
    };

    if (index === 0) return fn();

    return fn;
}

/** Resolve the middleware module and it's arguments. */
function resolveMiddleware(middleware, index) {
    const name = middleware[index];

    if (!name) return null;

    if (name.startsWith('can:')) {
        return {fn: middlewares.permission, arguments: resolveMiddlewareArgs(name)}
    }

    return {fn: middlewares[name], arguments: []};
}

/** Resolve Laravel-style middleware notation `name:arg1,arg2` */
function resolveMiddlewareArgs(name) {
    return name.split(':').pop().split(',');
}
