import axios from 'axios';

let currentTeamId = null;

// Configure defaults

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.defaults.headers.common['X-Brightfish-UI'] = import.meta.env.APP_NAME_ABBR;

axios.defaults.baseURL = import.meta.env.APP_URL;

axios.defaults.withCredentials = true;

/**
 * Add the current team's ID to all requests using this interceptor.
 * @type {((function(*): {})|(function(*): void)|*)[]}
 */
const teamIdInterceptor = [function (config) {
    if (!currentTeamId) {
        return config;
    }

    if (config['method'].toUpperCase() === 'GET') {
        config.params = config.params || {};
        config.params.team_id = currentTeamId;
    } else {
        config.data = config.data || {};
        config.data.team_id = currentTeamId;
    }

    return config;
}, function (error) {
    console.log('axios interceptor error', error);
}];

export default {
    teamIdInterceptor,
    setCurrentTeam(teamId) {
        currentTeamId = teamId;
    },
    unsetCurrentTeam() {
        currentTeamId = null;
    }
}
