<template>
    <v-footer>
        <v-row align="center" no-gutters>
            <v-col cols="6">
                <p class="text-sm-left">
                    Spottix Suite {{ version }} ♥ by
                    <a href="https://brightfish.be" class="text-darkText" target="_blank">Brightfish</a>
                </p>
            </v-col>
            <v-col class="text-sm-right" cols="6">
                <a v-for="(item, i) in links"
                   :key="i"
                   class="ml-4 text-darkText text-decoration-none"
                   target="_blank"
                   :href="item.url">
                    {{ item.anchor }}
                </a>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
import constants from "../constants.js";

export default {
    created() {
        this.version = constants.app.VERSION;

        this.links = [
            {
                anchor: 'Documentation',
                url: '#'
            },
            {
                anchor: 'Support',
                url: '#'
            }
        ];
    },
}
</script>
