<template>
    <v-navigation-drawer :rail="hiding" :border="0" class="app-menu">
        <div class="pa-5">
            <a href="/">Spottix</a>
        </div>

        <v-list v-model:opened="open" nav class="pa-4">
            <template v-for="(item, i) in items" :key="i">
                <v-list-item v-if="item.to && userCan(item.ability)"
                             :title="item.title"
                             :to="item.to"/>

                <v-list-group v-else-if="userCan(item.ability)" :value="item.title">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props"
                                     :title="item.title"
                                     :to="item.to"/>
                    </template>

                    <v-list-item
                        v-for="(child, k) in item.children"
                        v-show="userCan(child.ability)"
                        :key="'c' + k"
                        :prepend-icon="'spx:' + child.icon"
                        :title="child.title"
                        :value="child.title"
                        :to="child.to"/>

                    <v-divider class="my-3" />
                </v-list-group>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import {mapGetters} from "vuex";
import menuItems from "../../Shared/menuItems.js";

export default {
    props: ['show', 'user'],
    data() {
        this.items = menuItems;

        return {
            open: menuItems.map(item => item.title),
            hiding: !this.show
        }
    },
    watch: {
        show(state) {
            this.hiding = !state;
        },
    },
    computed: {
        ...mapGetters('auth', {
            userCan: 'userCan'
        }),
    },
}
</script>
