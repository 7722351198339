<template>
    <v-snackbar
        v-model="show"
        :text="message"
        :color="type"
        :timeout="timeout"
        attach="true"
        variant="flat"
        rounded="pill"/>
</template>

<script>
import {state} from "./index.js";

export default {
    data() {
        return state
    },
}
</script>
