<template>
    <div class="view-404">
        Page not found
    </div>
</template>

<script>
export default {
    components: {
    },
}
</script>
