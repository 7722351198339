<template>
    <div class="view-output-profile-edit">
        <generic-view-title :title="(createMode ? 'Create a new' : 'Edit the') + ' output profile'">
            <v-btn type="submit"
                   @click.prevent="submitForm"
                   prepend-icon="spx:IconDeviceFloppy"
                   text="Save"/>
        </generic-view-title>

        <v-form ref="form">
            <v-sheet class="pa-6 pb-0 mb-6" rounded>
                <h5 class="mb-3">General</h5>
                <v-row class="pt-4">
                    <v-col cols="12">
                        <v-text-field
                            v-model="attr.name"
                            label="Name"
                            variant="outlined"
                            rounded="lg"
                            required
                            :rules="[slugServerError, $options.rules.required]"
                            @input="e => createMode ? checkSlugUniqueness(e, 'output_profiles') : null">
                        <template #details>
                            Folder name & slug: {{ attr.slug }}
                        </template>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-sheet>

            <v-sheet class="pa-6 pb-0 mb-6" rounded>
                <h5 class="mb-3">Audio specification</h5>
                <v-row class="pt-4">
                    <v-col cols="4">
                        <v-select
                            v-model="attr.audio_channels"
                            :items="$options.constants.AUDIO_CHANNELS"
                            :rules="[$options.rules.oneOf($options.constants.AUDIO_CHANNELS), errorMessages.audio_channels]"
                            label="Audio channels"
                            variant="outlined"
                            rounded="lg"
                        ></v-select>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            v-model="attr.volume_min"
                            type="number"
                            step="1"
                            label="Min Leq(m) volume"
                            variant="outlined"
                            rounded="lg"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            v-model="attr.volume_max"
                            type="number"
                            step="1"
                            label="Max Leq(m) volume"
                            variant="outlined"
                            rounded="lg"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-sheet>

            <v-sheet class="pa-6 pb-0 mb-6" rounded>
                <h5 class="mb-3">Video specification</h5>
                <v-row class="pt-4">
                    <v-col cols="3">
                        <v-select
                            v-model="attr.fps"
                            :items="$options.constants.VIDEO_FPS"
                            :rules="[$options.rules.oneOf($options.constants.VIDEO_FPS), errorMessages.fps]"
                            label="Frame rate"
                            variant="outlined"
                            rounded="lg"
                        ></v-select>
                    </v-col>
                    <v-col cols="3">
                        <v-select
                            v-model="attr.resolution"
                            :items="$options.constants.VIDEO_RESOLUTIONS"
                            :rules="[$options.rules.oneOf($options.constants.VIDEO_RESOLUTIONS), errorMessages.resolution]"
                            label="Resolution"
                            variant="outlined"
                            rounded="lg"
                        ></v-select>
                    </v-col>
                    <v-col cols="3">
                        <v-select
                            v-model="attr.aspect_ratio"
                            :items="$options.constants.VIDEO_ASPECT_RATIOS"
                            :rules="[$options.rules.oneOf($options.constants.VIDEO_ASPECT_RATIOS), errorMessages.aspect_ratio]"
                            label="Aspect Ratio"
                            variant="outlined"
                            rounded="lg"
                        ></v-select>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field
                            v-model="attr.seconds_after"
                            type="number"
                            min="0"
                            step="0.3"
                            label="Blank seconds after"
                            variant="outlined"
                            rounded="lg"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-sheet>

            <v-sheet class="pa-6 pb-0 mb-3" rounded>
                <h5 class="mb-3">DCP specification</h5>
                <v-row class="pt-4">
                    <v-col cols="3">
                        <v-select
                            v-model="attr.format"
                            :items="$options.constants.DCP_FORMATS"
                            :rules="[$options.rules.oneOf($options.constants.DCP_FORMATS), errorMessages.format]"
                            label="Format"
                            variant="outlined"
                            rounded="lg"
                            @update:model-value="toggleKdmSwitch"
                        ></v-select>
                    </v-col>
                    <v-col cols="3">
                        <v-switch
                            id="output-profile-kdm"
                            v-model="attr.kdm"
                            label="Requires KDM"
                            variant="outlined"
                            rounded="lg"
                        ></v-switch>
                    </v-col>
                    <v-col cols="6">
                        <v-autocomplete
                            v-model="attr.allowed_lang"
                            :items="languages ? Object.values(languages): []"
                            :rules="[(languages ? $options.rules.manyKeyOf(languages) : true), errorMessages.allowed_lang]"
                            chips
                            multiple
                            clear-on-select
                            required
                            label="Allowed languages"
                            variant="outlined"
                            rounded="lg"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </v-sheet>

            <v-row class="mb-1">
                <v-col cols="12" class="text-center">
                    <v-btn type="submit"
                           @click.prevent="submitForm"
                           prepend-icon="spx:IconDeviceFloppy"
                           text="Save"/>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>
<script>
import {mapState} from "vuex";
import GenericViewTitle from "../../Shared/Components/GenericViewTitle.vue";
import AppConstants from "../../Application/constants.js";
import validationMixin from "../../Shared/Mixins/validationMixin.js";
import slugGenerationMixin from "../Mixins/slugGenerationMixin.js";
import formHelperMixin from "../../Shared/Mixins/crudHelperMixin.js";
import types from "../../Shared/storeTypes.js";

const
    constants = AppConstants.plan.outputProfiles,
    defaults = {
        name: '',
        slug: '',
        // Audio
        volume_min: constants.AUDIO_VOLUME_MIN,
        volume_max: constants.AUDIO_VOLUME_MAX,
        audio_channels: constants.AUDIO_CHANNELS_DEFAULT,
        audio_sample_rate: constants.AUDIO_SAMPLE_RATE,
        audio_sample_depth: constants.AUDIO_SAMPLE_DEPTH,
        // Video
        aspect_ratio: constants.VIDEO_ASPECT_RATIO_DEFAULT,
        resolution: constants.VIDEO_RES_DEFAULT,
        fps: constants.VIDEO_FPS_DEFAULT,
        seconds_after: constants.VIDEO_SECONDS_AFTER,
        // DCP
        format: constants.DCP_FORMAT_DEFAULT,
        kdm: constants.DCP_KDM_DEFAULT,
        allowed_lang: constants.DCP_LANG_DEFAULT,
    };

export default {
    mixins: [validationMixin, slugGenerationMixin, formHelperMixin],
    constants: constants,
    components: {
        GenericViewTitle,
    },
    data() {
        return {
            attr: Object.assign({}, defaults),
        }
    },
    computed: {
        ...mapState('shared', {
            languages: types.LANGUAGES,
        }),
    },
    /**
     * Assign non-reactive data and fetch data from vuex/server.
     * @return void
     */
    created() {
        this.modelId = +this.$route.params.id;

        this.createMode = !this.modelId;

        if (!this.createMode) {
            this.fetchModel(this.modelId);
        }

        this.$store.dispatch('shared/fetchLanguages');
    },
    methods: {
        /**
         * Submit the form through the vuex store.
         * @returns void
         */
        async submitForm () {
            if (!await this.validateForm(this.$refs.form)) {
                return;
            }

            const http = this.createMode
                ? this.$store.dispatch('outputProfiles/store', this.attr)
                : this.$store.dispatch('outputProfiles/update', {id: this.modelId, attr: this.attr});

            http.then(data => this.attr = data)
                .then(data => {
                    this.submitMessage('Output profile', this.createMode);
                    this.modelId = data.id;
                    this.createMode = false;
                })
                .catch(error => this.handleFormError(this.$refs.form, error));
        },
        /**
         * Fetch the model's attributes from the store/server.
         * @param {number} id
         * @returns void
         */
        fetchModel(id) {
            this.$store
                .dispatch('outputProfiles/get', id)
                .then(profile => {
                    this.$data.attr = Object.assign(this.$data.attr, { ...profile})
                });
        },
        toggleKdmSwitch(value) {
            this.$el.querySelector('#output-profile-kdm')
                .disabled = value !== constants.DCP_FORMAT_SMPTE;
        },
    }
}
</script>
