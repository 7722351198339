<template>
    <div class="view-team-settings">
        <generic-view-title title="Settings"/>
    </div>
</template>
<script>
import GenericViewTitle from "../../Shared/Components/GenericViewTitle.vue";

export default {
    components: {GenericViewTitle},
    data: () => ({
    }),
    methods: {

    }
}
</script>
