<template>
    <div class="view-user-edit">
        <generic-view-title :title="generateViewTitle()">
            <v-btn type="submit"
                   @click.prevent="submitForm"
                   icon="spx:IconDeviceFloppy"
                   density="comfortable"
                   rounded="xl"/>
        </generic-view-title>

        <v-form ref="form">
            <v-sheet class="pa-6 pb-0 mb-6" rounded>
                <v-row class="pt-4">
                    <v-col cols="6" class="pb-0">
                        <v-text-field
                            v-model="attr.firstname"
                            type="text"
                            label="Firstname"
                            variant="outlined"
                            rounded="lg"
                            required
                            :rules="[$options.rules.required, errorMessages.firstname || true]"/>
                    </v-col>
                    <v-col cols="6" class="pb-0">
                        <v-text-field
                            v-model="attr.name"
                            type="text"
                            label="Name"
                            variant="outlined"
                            rounded="lg"
                            required
                            :rules="[$options.rules.required, errorMessages.name || true]"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-text-field
                            v-model="attr.email"
                            type="email"
                            :rules="[$options.rules.required, errorMessages.email || true]"
                            required
                            label="Email"
                            variant="outlined"
                            rounded="lg"/>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-if="createMode"
                            v-model="attr.password"
                            :append-inner-icon="showPassword ? 'spx:IconEye' : 'spx:IconEyeOff'"
                            :rules="[$options.rules.required, $options.rules.min(10), errorMessages.password || true]"
                            :type="showPassword ? 'text' : 'password'"
                            hint="At least 1O characters containing upper and lowercase letters, numbers and symbols"
                            label="Password"
                            name="input-10-1"
                            counter
                            @click:append="showPassword = !showPassword"
                            variant="outlined"
                            rounded="lg"/>
                    </v-col>
                </v-row>
            </v-sheet>

            <v-sheet class="pa-6 pb-0 mb-6" rounded>
                <v-row class="pt-4">
                    <v-col cols="6">
                        <v-select v-model="attr.roles"
                                  :items="roles"
                                  :rules="[$options.rules.required, errorMessages.role || true]"
                                  required
                                  label="Role"
                                  variant="outlined"
                                  rounded="lg"/>
                    </v-col>
                    <v-col cols="6">
                        <v-select v-if="!teamScoped"
                                  v-model="attr.team_id"
                                  :items="teams"
                                  :rules="[errorMessages.users || true]"
                                  label="Team"
                                  variant="outlined"
                                  rounded="lg"/>
                    </v-col>
                </v-row>
            </v-sheet>

            <v-row class="mb-1">
                <v-col cols="12" class="text-center">
                    <v-btn type="submit"
                           @click.prevent="submitForm"
                           icon="spx:IconDeviceFloppy"
                           density="default"
                           rounded="xl"/>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>
<script>
import {mapState} from "vuex";
import GenericViewTitle from "../../Shared/Components/GenericViewTitle.vue";
import validationMixin from "../../Shared/Mixins/validationMixin.js";
import formHelperMixin from "../../Shared/Mixins/crudHelperMixin.js";
import constants from "../../Application/constants.js";

const defaults = {
    firstname: '',
    name: '',
    email: '',
    password: '',
    roles: ''
};

export default {
    mixins: [validationMixin, formHelperMixin],
    components: {
        GenericViewTitle,
    },
    data() {
        this.setViewState();

        return {
            attr: Object.assign({}, defaults),
            role: null,
            showPassword: false,
            roles: this.getRoles(constants.auth.AVAILABLE_ROLES),
        }
    },
    computed: mapState({
        teams (state, getters) {
            return this.teamScoped ? [] : getters['team/teams'].map(p => ({
                value: p.id,
                title: p.name
            }))
        }
    }),
    watch: {
        attr(newAttr) {
            this.setRolesAttribute(newAttr);
        },
    },
    methods: {
        /**
         * Submit the form through the vuex store.
         * @returns void
         */
        async submitForm () {
            if (!await this.validateForm(this.$refs.form)) {
                return;
            }

            const http = this.createMode
                ? this.$store.dispatch(`${this.storeNamespace}/store`, this.attr)
                : this.$store.dispatch(`${this.storeNamespace}/update`, {id: this.modelId, attr: this.attr});

            http.then(data => this.attr = data)
                .then(data => {
                    this.submitMessage(this.teamScoped ? 'Member' : 'User', this.createMode);
                    this.modelId = data.id;
                    this.createMode = false;
                })
                .catch(error => this.handleFormError(this.$refs.form, error));
        },
        /**
         * Fetch the model's attributes from the store/server.
         * @param {number} id
         * @return void
         */
        fetchModel(id) {
            this.$store
                .dispatch(`${this.storeNamespace}/get`, id)
                .then(user => {
                    this.attr = Object.assign(this.attr, { ...user});
                    this.setRolesAttribute(this.attr);
                });
        },
        /**
         * Currently only a single role is supported.
         * @param {object} attr
         */
        setRolesAttribute(attr) {
            this.attr.roles = attr.roles.pop().name;
        },
        /**
         * Build the view title.
         * @return string
         */
        generateViewTitle() {
            const prefix = this.createMode ? 'Create a new' : 'Edit the',
                suffix = this.teamScoped ? ' team member' : ' user';

            return prefix + suffix;
        },
        /**
         * Assign non-reactive data and fetch data from vuex/server.
         * @return void
         */
        setViewState() {
            this.modelId = +this.$route.params.id;

            this.teamScoped = /^\/team\//i.test(this.$route.path);

            this.createMode = !this.modelId;

            this.routeBasePath = this.teamScoped ? 'team' : 'admin';

            this.storeNamespace = this.teamScoped ? 'teamMembers' : 'users';

            if (!this.createMode) {
                this.fetchModel(this.modelId);
            }

            if (!this.teamScoped) {
                this.$store.dispatch('team/index');
            }
        },
        getRoles(roles) {
            // Only Super Admins can create Super admins
            if (!this.$store.getters['auth/is']('Super Admin')) {
                return roles.filter(role => role !== 'Super Admin')
            }

            return roles;
        }
    },
}
</script>
