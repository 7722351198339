import types from "../Shared/storeTypes.js";
import constants from "../Application/constants.js";
import UserClient from "./UserClient.js";
import userStoreActions from "./userStoreActions.js";

const
    userClient = new UserClient(),
    state = {
        [types.USERS]: [],
        [types.USERS_TOTAL]: 0,
        [types.USERS_PAGE]: 1,
        [types.PAGINATION_PER_PAGE]: constants.shared.PAGINATE_PER_PAGE_DEFAULT,
    },
    mutations = {
        [types.USERS](state, data) {
            state[types.USERS] = data.data;
            state[types.USERS_TOTAL] = data.total;
            state[types.USERS_PAGE] = data.current_page;
            state[types.PAGINATION_PER_PAGE] = data.per_page;
        },
    },
    getters = {
        /**
         * Return all users.
         * @param {object} state
         * @return {object}
         */
        users: state => state[types.USERS],
        /**
         * Return a specific user.
         * @param {object} state
         * @return {object}
         */
        user: state => model => {
            const id = isNaN(model) ? model.id : +model;

            return state[types.USERS].filter(user => user.id === id).shift();
        },
    },
    actions = userStoreActions(userClient, {
        type: types.USERS,
        typePage: types.USERS_PAGE,
        typeTotal: types.USERS_TOTAL,
    });

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
