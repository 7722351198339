const
    storage = window.localStorage,
    types = {
        KEYS: 'spx-keys',
        VERSION: 'spx-version',
    };

function get(key) {
    return JSON.parse(storage.getItem(key));
}

function set(key, value) {
    value = typeof value === 'string' ? value : JSON.stringify(value);

    _trackKey(key);

    storage.setItem(key, value);
}

function reset(version) {
    const keys = get(types.KEYS);

    keys.forEach(key => storage.removeItem(key));

    set(types.KEYS, []);

    set(types.VERSION, version);
}

function _trackKey(key) {
    let keys = (get(types.KEYS) || []);

    keys.push(key);

    storage.setItem(types.KEYS, keys);
}

export default {
    get,
    set,
    reset,
}
