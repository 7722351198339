import types from "../Shared/storeTypes.js";

const helpers = {
    /**
     * Verify if index page should be requested from server.
     * @param {object} state
     * @param {{refresh: boolean, sortBy: string, page: number, perPage: number}} parameters
     * @param {object} users
     * @param {string} typePage
     * @returns {boolean}
     */
    shouldFetchAnew(state, parameters, users, {typePage}) {
        return parameters.refresh
            || parameters.sortBy
            || parameters.page !== state[typePage]
            || parameters.perPage !== state[types.PAGINATION_PER_PAGE]
            || !users.length;
    },
    /**
     * Cleanup data before storage on server.
     * @param {object} data
     * @param {boolean} isNew
     * @returns {object}
     */
    sanitize(data, isNew) {
        data = Object.assign({}, data);

        data.firstname = data.firstname.trim();
        data.name = data.name.trim();
        data.email = data.email.trim();
        data.roles = [data.roles.trim()];

        delete data.id;
        delete data.created_at;
        delete data.updated_at;

        if (!isNew) {
            delete data.password;
        }

        return data;
    },
};

export default function userStoreActions (userClient, actionParams) {
    return {
        /**
         * Fetch the paginated resource.
         * @param {function} commit
         * @param {object} getters
         * @param {object} state
         * @param {object} parameters
         * @return {Promise<array|null>}
         */
        index({commit, getters, state}, parameters = {}) {
            const users = getters.users;

            if (parameters.page) {
                parameters.perPage = parameters.perPage || getters.perPage;
            }

            if (!helpers.shouldFetchAnew(state, parameters, users, actionParams)) {
                return Promise.resolve(users);
            }

            return userClient
                .getUsers(parameters)
                .then(response => {
                    commit(actionParams.type, response.data);

                    return response.data.data;
                });
        },
        /**
         * Fetch a single resource.
         * @param {object} getters
         * @param {number} id
         * @return {Promise<object|null>}
         */
        get({getters}, id) {
            const user = getters.user(id);

            if (user) {
                return Promise.resolve(user);
            }

            return userClient.getUser(id)
                .then(response => response.data.data);
        },
        /**
         * Update a resource and fetch the current page again.
         * @param {function} dispatch
         * @param {number} id
         * @param {object} attr
         * @return {Promise<object>}
         */
        async update({dispatch}, {id, attr}) {
            attr = helpers.sanitize(attr, false);

            const response = await userClient.updateUser(id, attr)
                .then(response => response.data.data);

            dispatch('index', {refresh: true});

            return response;
        },
        /**
         * Store a new resource and fetch the current page again.
         * @param {function} dispatch
         * @param {object} attr
         * @return {Promise<object>}
         */
        async store({dispatch}, attr) {
            attr = helpers.sanitize(attr, true);

            const response = await userClient.storeUser(attr)
                .then(response => response.data.data);

            dispatch('index', {refresh: true});

            return response;
        },
        /**
         * Delete a resource and fetch the current page again.
         * @param {function} dispatch
         * @param {number} id
         * @return {Promise<null>}
         */
        destroy({dispatch}, id) {
            return userClient
                .deleteUser(id)
                .then(() => dispatch('index', {refresh: true}));
        },
    }
}
