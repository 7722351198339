<template>
    <div class="view-team">
        <router-view class="child-view"/>
    </div>
</template>
<script>
export default {
    data: () => ({
    }),
    methods: {

    }
}
</script>
