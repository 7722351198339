<template>
    <div class="view-adv"> <!-- the classnames 'view-advertisements', 'view-adverts', etc. get hidden by uBlock -->
        <generic-view-title title="Advertisements">
            <div class="d-inline-block">
                <v-btn prepend-icon="spx:IconFolderPlus"
                       :disabled="createDisabled"
                       text="New"
                       @click="create"/>
                <v-tooltip v-if="createDisabled"
                           activator="parent"
                           location="bottom"
                           :text="createDisabledReason"/>
            </div>
        </generic-view-title>

        <v-data-table-server v-model:items-length="total"
                             :headers="headers"
                             :items="advertisements"
                             :items-per-page="perPage"
                             :loading="loading"
                             :page="currentPage"
                             prev-icon="spx:pagePrev"
                             next-icon="spx:pageNext"
                             @update:options="seedTable">

            <template v-slot:item.name="{item}">
                <router-link :to="`/plan/advertisements/${item.id}`">{{ item.name }}</router-link>
            </template>

            <template v-slot:item.profile="{item}">
                <router-link :to="`/plan/output-profiles/${item.output_profile_id}`">{{ item.output_profile.name }}</router-link>
            </template>

            <template v-slot:item.campaign="{item}">
                <router-link :to="`/plan/campaigns/${item.campaign_id}`">{{ item.campaign.name }}</router-link>
            </template>

            <template v-slot:item.actions="{item}">
                <v-btn-secondary icon="spx:edit" @click.prevent="edit(item)" class="mr-2"/>
                <v-btn-secondary icon="spx:trash" @click.prevent="destroy(item)"/>
            </template>

        </v-data-table-server>
    </div>
</template>
<script>
import {mapState} from "vuex";
import GenericListItem from "../Components/OutputProfileListItem.vue";
import GenericViewTitle from "../../Shared/Components/GenericViewTitle.vue";
import types from "../../Shared/storeTypes.js";
import paginationUrlMixin from "../../Shared/Mixins/paginationMixin.js";
import formatDateMixin from "../../Shared/Mixins/formatDateMixin.js";
import crudHelperMixin from "../../Shared/Mixins/crudHelperMixin.js";

export default {
    mixins: [paginationUrlMixin, formatDateMixin, crudHelperMixin],
    components: {
        GenericViewTitle,
        GenericListItem
    },
    data() {
        this.headers = [
            {key: 'name', title: 'Name'},
            {key: 'rating', title: 'Rating'},
            {key: 'profile', title: 'Profile'},
            {key: 'campaign', title: 'Campaign'},
            {key: 'created_at', title: 'Created on', value: item => `${this.formatDate(item.created_at)}`},
            {key: 'actions', title: '', sortable: false, align: 'end'},
        ];

        return {
            createDisabled: false,
            createDisabledReason: '',
            team: this.$store.getters['team/current'],
        };
    },
    computed: {
        ...mapState('advertisements', {
            advertisements: types.ADVERTISEMENTS,
            total: types.ADVERTISEMENTS_TOTAL,
        }),
    },
    created() {
        this.allowAdCreation();
    },
    methods: {
        seedTable(options) {
            this.handlePagination('advertisements/index', {
                perPage: options.itemsPerPage,
                page: options.page,
            });
        },
        create() {
            this.$router.push('/plan/advertisements/create');
        },
        edit(ad) {
            this.$router.push(`/plan/advertisements/${ad.id}`);
        },
        destroy(ad) {
            this.$store.dispatch('advertisements/destroy', +ad.id)
                .then(() => this.deletedMessage('Advertisement'))
                .catch(e => this.handleHttpError(e));
        },
        async allowAdCreation() {
            const outputProfiles = await this.$store.dispatch('outputProfiles/index'),
                campaignCount = await this.$store.dispatch('campaigns/count');

            if (!outputProfiles.length) {
                this.createDisabled = true;
                this.createDisabledReason = 'Create an output profile first';

                return;
            }

            if (!campaignCount) {
                this.createDisabled = true;
                this.createDisabledReason = 'Create a campaign first';
            }
        }
    }
}
</script>
