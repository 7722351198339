<template>
    <div class="view-profile">
        Profile
    </div>
</template>
<script>
export default {
    data: () => ({
    }),
    methods: {

    }
}
</script>
