<template>
    <div class="view-notifications">
        <generic-view-title title="Notifications"/>

        <v-data-table-server v-model:items-length="total"
                             :headers="headers"
                             :items="notifications"
                             :items-per-page="perPage"
                             :loading="loading"
                             :page="currentPage"
                             prev-icon="spx:pagePrev"
                             next-icon="spx:pageNext"
                             @update:options="seedTable">

            <template v-slot:item.message="{item}">
                {{ item.data.message }}
            </template>

            <template v-slot:item.actions="{item}">
                <v-btn-secondary :icon="'spx:IconEye' + (item.read_at ? 'Off' : '')" @click.prevent="read(item)" class="mr-2"/>
                <v-btn-secondary icon="spx:trash" @click.prevent="destroy(item)"/>
            </template>

        </v-data-table-server>
    </div>
</template>
<script>
import {mapState} from "vuex";
import GenericViewTitle from "../Components/GenericViewTitle.vue";
import types from "../storeTypes.js";
import paginationUrlMixin from "../Mixins/paginationMixin.js";
import formatDateMixin from "../Mixins/formatDateMixin.js";
import crudHelperMixin from "../Mixins/crudHelperMixin.js";

export default {
    mixins: [paginationUrlMixin, formatDateMixin, crudHelperMixin],
    components: {
        GenericViewTitle,
    },
    data() {
        this.headers = [
            {key: 'message', title: 'Message', sortable: false},
            //{key: 'campaign', title: 'Campaign', sortable: false},
            {key: 'created_at', title: 'Created on', sortable: true, value: item => `${this.formatDate(item.created_at, 'DD MMM YYYY HH:mm:ss')}`},
            {key: 'actions', title: '', sortable: false, align: 'end'},
        ];

        return {
            createDisabled: false,
            createDisabledReason: '',
            team: this.$store.getters['team/current'],
        };
    },
    computed: {
        ...mapState('notifications', {
            notifications: types.NOTIFICATIONS,
            total: types.NOTIFICATIONS_TOTAL,
        }),
    },
    methods: {
        seedTable(options) {
            this.handlePagination('notifications/index', {
                perPage: options.itemsPerPage,
                page: options.page,
            });
        },
        read(notification) {
            const state = !notification.read_at;

            this.$store.dispatch('notifications/read', {uuid: notification.id, read: state})
                .catch(e => this.handleHttpError(e));
        },
        destroy(notification) {
            this.$store.dispatch('notifications/destroy', +notification.id)
                .then(() => this.deletedMessage('Notification'))
                .catch(e => this.handleHttpError(e));
        },
    }
}
</script>
