<template>
    <v-menu v-model="isOpen" :close-on-content-click="false">
        <template v-slot:activator="{ props }">
            <v-text-field
                :label="label"
                :model-value="formattedDate"
                readonly
                v-bind="props"
                variant="outlined"
                rounded="lg"
                prepend-icon=""
                :prepend-inner-icon="icon || 'spx:IconCalendarPlus'"
                :rules="rules"
                clearable
                :disabled="disabled"
                @click:clear="selectedDate = null"
            ></v-text-field>
        </template>
        <v-date-picker v-model="selectedDate"
                       hide-header
                       color="tertiary"
                       rounded="lg"
                       :min="$options.min"
                       :disabled="disabled"/>
    </v-menu>
</template>

<script>
import dayjs from 'dayjs';

export default {
    min: dayjs().format('YYYY-MM-DD'),
    displayFormat: 'DD MMM YYYY',
    props: [
        'label', 'modelValue', 'icon', 'disabled', 'rules',
    ],
    data() {
        let initial = this.modelValue ? dayjs(this.modelValue) : null;

        return {
            isOpen: false,
            selectedDate: initial,
            formattedDate: initial ? initial.format(this.$options.displayFormat) : null,
        }
    },
    watch: {
        modelValue(newDate) {
            this.selectedDate = newDate;
        },
        selectedDate(newDate) {
            if (!newDate) {
                return this.$emit('update:modelValue', null);
            }

            newDate = dayjs(newDate);

            this.formattedDate = newDate.format(this.$options.displayFormat);

            this.$emit('update:modelValue', newDate.format('YYYY-MM-DD'));
        }
    }
}
</script>
