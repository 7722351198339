import logger from "../../Application/Services/logger.js";

export default {
    methods: {
        submitMessage(modelName, create) {
            this.$notify.success(`${modelName} successfully ${create ? 'created' : 'updated'}`);
        },
        deletedMessage(modelName) {
            this.$notify.success(`${modelName} successfully deleted`);
        },
        handleHttpError(error) {
            if (error.response && error.response.data && error.response.data.message) {
                this.$notify.error(error.response.data.message);
            } else {
                this.$notify.error(error.message);

                logger.error(error);
            }
        },
    }
}
