import OutputProfiles from "./Views/OutputProfiles.vue";
import OutputProfileEdit from "./Views/OutputProfileEdit.vue";
import Campaigns from "./Views/Campaigns.vue";
import CampaignEdit from "./Views/CampaignEdit.vue";
import Advertisements from "./Views/Advertisements.vue";
import AdvertisementEdit from "./Views/AdvertisementEdit.vue";

export default [
    /*  Output profile routes
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
    {
        path: '/plan/output-profiles',
        component: OutputProfiles,
        meta: {
            middleware: ['auth', 'subscribed']
        },
    },
    {
        path: '/plan/output-profiles/create',
        component: OutputProfileEdit,
        meta: {
            middleware: ['auth', 'subscribed']
        },
    },
    {
        path: '/plan/output-profiles/:id',
        component: OutputProfileEdit,
        meta: {
            middleware: ['auth', 'subscribed']
        },
    },
    /*  Campaign routes
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
    {
        path: '/plan/campaigns',
        component: Campaigns,
        meta: {
            middleware: ['auth', 'subscribed']
        },
    },
    {
        path: '/plan/campaigns/create',
        component: CampaignEdit,
        meta: {
            middleware: ['auth', 'subscribed']
        },
    },
    {
        path: '/plan/campaigns/:id',
        component: CampaignEdit,
        meta: {
            middleware: ['auth', 'subscribed']
        },
    },
    /*  Advertisement routes
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
    {
        path: '/plan/advertisements',
        component: Advertisements,
        meta: {
            middleware: ['auth', 'subscribed']
        },
    },
    {
        path: '/plan/advertisements/create',
        component: AdvertisementEdit,
        meta: {
            middleware: ['auth', 'subscribed']
        },
    },
    {
        path: '/plan/advertisements/:id',
        component: AdvertisementEdit,
        meta: {
            middleware: ['auth', 'subscribed']
        },
    },
];
