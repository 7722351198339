<template>
    <v-row class="mb-1">
        <v-col cols="12">
            <v-card variant="flat" rounded class="px-4 py-3">
                <v-row no-gutters class="align-center">
                    <v-col cols="6">
                        <h3 class="text-h3">{{ title }}</h3>
                    </v-col>
                    <v-col cols="6" class="text-right">
                        <slot></slot>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        title: String,
    },
    data() {
        return {};
    },
}
</script>
