import constants from "../../Application/constants.js";

export default {
    data() {
        return {
            loading: false,
        };
    },
    /** Fixes <v-data-table-server> bug {@see https://github.com/vuetifyjs/vuetify/issues/17966} */
    created() {
        this.firstLoad = true;
    },
    methods: {
        handlePagination(storeAction, options) {
            let query = {
                perPage: options.itemsPerPage,
                page: options.page,
            };

            if (options.sortBy && options.sortBy.length) {
                query.sortBy = options.sortBy[0].key;
                query.sort = options.sortBy[0].order;
            }

            if (this.firstLoad) {
                query.page = this.currentPage;

                this.firstLoad = false;
            }

            this.loading = true;

            return this.$store
                .dispatch(storeAction, query)
                .then(() => {
                    this.setRouteQuery(query);

                    this.loading = false;
                });
        },
        /** Reset the URL of the current page. */
        setRouteQuery(query) {
            this.$router.push({
                query: Object.assign({}, this.$route.query, {
                    page: query.page,
                    perPage: query.perPage
                })
            });
        }
    },
    computed: {
        currentPage() {
            return +this.$route.query.page || 1
        },
        perPage() {
            return +this.$route.query.perPage || constants.shared.PAGINATE_PER_PAGE_DEFAULT;
        }
    }
}
