export default [
    {
        title: 'Dashboard',
        to: '/dashboard',
        ability: '*',
    },
    {
        title: 'Plan',
        ability: 'manage_mod_plan',
        children: [
            {
                title: 'Campaigns',
                icon: 'IconFlagCog',
                to: '/plan/campaigns',
                ability: 'manage_mod_plan',
            },
            {
                title: 'Advertisements',
                icon: 'IconFlagCog',
                to: '/plan/advertisements',
                ability: 'manage_mod_plan',
            },
            {
                title: 'Output profiles',
                icon: 'IconFolderCog',
                to: '/plan/output-profiles',
                ability: 'manage_mod_plan',
            }
        ]
    },
    {
        title: 'Team',
        ability: 'manage_team',
        children: [
            {
                title: 'Notifications',
                icon: 'IconEye',
                to: '/notifications',
                ability: 'manage_team',
            },
            {
                title: 'Team Members',
                icon: 'IconUsers',
                to: '/team/users',
                ability: 'manage_team',
            },
            {
                title: 'Settings',
                icon: 'IconSettings',
                to: '/team/settings',
                ability: 'manage_team',
            },
            {
                title: 'Billing',
                icon: 'IconWallet',
                href: '/billing/team/1',
                ability: 'manage_team',
            }
        ]
    },
    {
        title: 'Admin',
        ability: 'manage_teams',
        children: [
            {
                title: 'Teams',
                icon: 'IconUsersGroup',
                to: '/admin/teams',
                ability: 'manage_teams',
            },
            {
                title: 'Users',
                icon: 'IconUsers',
                to: '/admin/users',
                ability: 'manage_teams',
            },
        ]
    }
]
