import types from "../Shared/storeTypes.js";
import PlanClient from "./planClient.js";
import constants from "../Application/constants.js";

const
    state = {
        [types.ADVERTISEMENTS]: [],
        [types.ADVERTISEMENTS_TOTAL]: 0,
        [types.ADVERTISEMENTS_PAGE]: 1,
        [types.PAGINATION_PER_PAGE]: constants.shared.PAGINATE_PER_PAGE_DEFAULT,
    },
    mutations = {
        [types.ADVERTISEMENTS](state, data) {
            state[types.ADVERTISEMENTS] = data.data;
            state[types.ADVERTISEMENTS_TOTAL] = data.total;
            state[types.ADVERTISEMENTS_PAGE] = data.current_page;
            state[types.PAGINATION_PER_PAGE] = data.per_page;
        },
    },
    getters = {
        /**
         * Return the current index view page.
         * @param {object} state
         * @return {number}
         */
        page: state => state[types.ADVERTISEMENTS_PAGE],
        /**
         * Return the current index view page.
         * @param {object} state
         * @return {number}
         */
        perPage: state => state[types.PAGINATION_PER_PAGE],
        /**
         * Return all advertisements.
         * @param {object} state
         * @return {object}
         */
        advertisements: state => state[types.ADVERTISEMENTS],
        /**
         * Return a specific advertisement from state.
         * @param {object} state
         * @return {object}
         */
        advertisement: state => (model) => {
            const id = isNaN(model) ? model.id : +model;

            return state[types.ADVERTISEMENTS].filter(advertisement => advertisement.id === id).shift();
        },
    },
    helpers = {
        shouldFetchAnew(parameters) {
            return parameters.refresh
                || parameters.sortBy
                || parameters.page !== state[types.ADVERTISEMENTS_PAGE]
                || parameters.perPage !== state[types.PAGINATION_PER_PAGE];
        },
        /**
         * Cleanup data before storage on server.
         * @param {object} data
         * @returns {object}
         */
        sanitize(data) {
            data = Object.assign({}, data);

            data.name = data.name.trim();

            delete data.id;
            delete data.camapign;
            delete data.output_profile;
            delete data.created_at;
            delete data.updated_at;

            return data;
        },
    },
    actions = {
        /**
         * Verify if index page should be requested from server.
         * @param {function} commit
         * @param {object} getters
         * @param {object} parameters
         * @return {Promise<object|null>}
         */
        index({commit, getters}, parameters = {}) {
            const advertisements = getters.advertisements;

            parameters.page = parameters.page || getters.page;

            parameters.perPage = parameters.perPage || getters.perPage;

            if (advertisements.length && !helpers.shouldFetchAnew(parameters, advertisements)) {
                return Promise.resolve(advertisements);
            }

            return PlanClient
                .getAdvertisements(parameters.page, parameters.perPage)
                .then(response => {
                    commit(types.ADVERTISEMENTS, response.data);

                    return response.data.data;
                });
        },
        /**
         * Fetch a single resource.
         * @param {object} getters
         * @param {number} id
         * @return {Promise<object|null>}
         */
        get({getters}, id) {
            const advertisement = getters.advertisement(id);

            if (advertisement) {
                return Promise.resolve(advertisement);
            }

            return PlanClient.getAdvertisement(id)
                .then(response => response.data.data);
        },
        /**
         * Update a resource and fetch the current page again.
         * @param {function} dispatch
         * @param {number} id
         * @param {object} attr
         * @return {Promise<object>}
         */
        async update({dispatch}, {id, attr}) {
            attr = helpers.sanitize(attr);

            const response = await PlanClient.updateAdvertisement(id, attr)
                .then(response => response.data.data);

            dispatch('index', {refresh: true});

            return response;
        },
        /**
         * Store a new resource and fetch the current page again.
         * @param {function} dispatch
         * @param {object} attr
         * @return {Promise<object>}
         */
        async store({dispatch}, attr) {
            attr = helpers.sanitize(attr);

            const response = await PlanClient.storeAdvertisement(attr)
                .then(response => response.data.data);

            dispatch('index', {refresh: true});

            return response;
        },
        /**
         * Delete a resource and fetch the current list again.
         * @param {function} dispatch
         * @param {object} model
         * @return {Promise<object|null>}
         */
        destroy({dispatch}, model) {
            return PlanClient
                .deleteAdvertisement(model)
                .then(() => dispatch('index', {refresh: true}));
        },
    };

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
