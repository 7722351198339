import { reactive } from "vue";

const
    state = reactive({
        type: '',
        message: '',
        show: false,
        timeout: 5000
    }),
    notify = {
        install(app) {
            //app.component('toast', Toast);

            app.config.globalProperties.$notify = {
                success: msg => show('success', msg),
                error: msg => show('error', msg),
            }
        },
    };


function show(type, msg) {
    state.type = type;
    state.message = msg;
    state.show = true;
}

export default notify;

export {
    state
};
