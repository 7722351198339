import Notifications from "../Shared/Views/Notifications.vue";

export default [
    {
        path: '/notifications',
        component: Notifications,
        meta: {
            middleware: ['auth', 'subscribed']
        },
    },
];
