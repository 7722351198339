<template>
    <div class="view-team-edit">
        <generic-view-title :title="(createMode ? 'Create a new' : 'Edit the') + ' team'">
            <v-btn type="submit"
                   @click.prevent="submitForm"
                   icon="spx:IconDeviceFloppy"
                   density="comfortable"
                   rounded="xl"/>
        </generic-view-title>

        <v-form ref="form">
            <v-sheet class="pa-6 pb-0 mb-6" rounded>
                <v-row class="pt-4">
                    <v-col cols="6">
                        <v-text-field
                            v-model="attr.name"
                            type="text"
                            label="Name"
                            variant="outlined"
                            rounded="lg"
                            required
                            :rules="[$options.rules.required, errorMessages.name || true]"
                            @input="e => createMode ? checkSlugUniqueness(e, 'campaigns') : null">
                            <template #details>
                                Folder name: {{ attr.slug }}
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-select
                            v-model="attr.owner_id"
                            :items="usersWithoutTeam"
                            :rules="[$options.rules.required, errorMessages.users]"
                            required
                            no-data-text="Create a new user first"
                            label="Team Manager"
                            variant="outlined"
                            rounded="lg"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-sheet>

            <v-row class="mb-1">
                <v-col cols="12" class="text-center">
                    <v-btn type="submit"
                           @click.prevent="submitForm"
                           icon="spx:IconDeviceFloppy"
                           density="default"
                           rounded="xl"/>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>
<script>
import GenericViewTitle from "../../Shared/Components/GenericViewTitle.vue";
import validationMixin from "../../Shared/Mixins/validationMixin.js";
import slugGenerationMixin from "../../Plan/Mixins/slugGenerationMixin.js";
import formHelperMixin from "../../Shared/Mixins/crudHelperMixin.js";
import UserClient from "../../Auth/UserClient.js";

const
    constants = {},
    defaults = {
        owner_id: null,
        name: '',
        slug: '',
    };

export default {
    mixins: [validationMixin, slugGenerationMixin, formHelperMixin],
    constants: constants,
    components: {
        GenericViewTitle,
    },
    data() {
        return {
            attr: Object.assign({}, defaults),
            usersWithoutTeam: [],
        }
    },
    /**
     * Assign non-reactive data and fetch data from vuex/server.
     * @return void
     */
    created() {
        this.modelId = +this.$route.params.id;

        this.createMode = !this.modelId;

        if (!this.createMode) {
            this.fetchModel(this.modelId);
        }

        this.setUsers();
    },
    methods: {
        /**
         * Submit the form through the vuex store.
         * @returns void
         */
        async submitForm () {
            if (!await this.validateForm(this.$refs.form)) {
                return;
            }

            const http = this.createMode
                ? this.$store.dispatch('team/store', this.attr)
                : this.$store.dispatch('team/update', {id: this.modelId, attr: this.attr});

            http.then(data => this.attr = data)
                .then(data => {
                    this.submitMessage('Team', this.createMode);
                    this.modelId = data.id;
                    this.createMode = false;
                })
                .catch(error => this.handleFormError(this.$refs.form, error));
        },
        /**
         * Fetch the model's attributes from the store/server.
         * @param {number} id
         * @returns void
         */
        fetchModel(id) {
            this.$store
                .dispatch('team/get', id)
                .then(profile => {
                    this.$data.attr = Object.assign(this.$data.attr, { ...profile})
                });
        },
        /**
         * Fetch all users to the team owner assignment.
         * @return void
         */
        setUsers() {
            (new UserClient).getUserNames().then(response => {
                let users = response.data.data;

                if (this.createMode) {
                    // Only users without a team id can be assigned as team owners.
                    users = users.filter(u => !u.team_id);
                } else {
                    // If team already exists, current team members can also be assigned as owners.
                    users = users.filter(u => (!u.team_id || u.team_id === this.modelId));
                }

                // Convert in Vuetify format
                this.usersWithoutTeam = users.map(p => ({
                    value: p.id,
                    title: p.firstname + ' ' + p.name
                }))
            });
        },
    }
}
</script>
