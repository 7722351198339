import Profile from "./Views/Profile.vue";
import Login from "./Views/Login.vue";

export default [
    {
        path: '/profile',
        component: Profile,
        meta: {
            middleware: ['auth', 'subscribed']
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            middleware: ['guest']
        },
    },
];
