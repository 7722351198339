import Dashboard from "./Views/Dashboard.vue";

export default [
    {
        path: '/dashboard',
        component: Dashboard,
        meta: {
            middleware: ['auth', 'subscribed']
        },
    },
];
