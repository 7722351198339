import {createVuetify} from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import {tablerIcons} from "./icons";
import DateAdapter from "@date-io/dayjs"

const
    darkTheme = {
        dark: true,
        colors: {
            primary: "#1e88e5",
            secondary: "#5e35b1",

            lightTertiary: '#13151d',
            surface: '#171923',

            // todo

            accent: "#82B1FF",
            error: "#FF5252",
            info: "#2196F3",
            success: "#4CAF50",
            warning: "#FFC107",
            lightblue: "#14c6FF",
            yellow: "#FFCF00",
            pink: "#FF1976",
            orange: "#FF8657",
            magenta: "#C33AFC",
            darkblue: "#1E2D56",
            gray: "#909090",
            neutralgray: "#9BA6C1",
            green: "#2ED47A",
            red: "#FF5c4E",
            darkblueshade: "#308DC2",
            lightgray: "#BDBDBD",
            lightpink: "#FFCFE3",
            white: "#FFFFFF",
            muted: "#6c757d",
        },
    },
    lightTheme = {
        dark: false,
        colors: {
            primary: '#5e35b1',
            secondary: '#ede7f6',
            tertiary: '#1e88e5',


            borderPrimary: '#00FF00',
            borderAccent: '#00FF00',

            lightTertiary: '#eef2f6',

            surface: '#fff',

            // todo

            info: '#03c9d7',
            success: '#00c853',
            accent: '#FFAB91',
            warning: '#ffc107',
            error: '#f44336',
            lightsuccess: '#b9f6ca',
            lighterror: '#f9d8d8',
            lightwarning: '#fff8e1',
            darkText: '#212121',
            lightText: '#616161',
            darkprimary: '#1565c0',
            darksecondary: '#4527a0',
            borderLight: '#d0d0d0',
            inputBorder: '#787878',

            gray100: '#fafafa',
            primary200: '#90caf9',
            secondary200: '#b39ddb'
        },
    };

const vuetify = createVuetify({
    components,
    directives,
    date: {
        adapter: DateAdapter,
    },
    theme: {
        defaultTheme: "lightTheme",
        themes: {
            darkTheme,
            lightTheme,
        },
    },
    icons: {
        defaultSet: "spx",
        sets: {
            spx: tablerIcons,
        },
    },
    aliases: {
        VBtnSecondary: components.VBtn,
    },
    defaults: {
        VBtn: {
            color: 'primary',
            variant: 'flat',
            density: 'default',
        },
        VBtnSecondary: {
            color: 'secondary',
            variant: 'flat',
            size: 'small',
            density: 'default',
        },
        VList: {
            color: 'primary',
        },
        VListItem: {
            elevation: 0,
            color: 'primary',
            variant: 'flat',
        },
        VTextField: {
            color: 'tertiary',
        }
    },
});

export default vuetify;
