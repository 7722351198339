import sharedClient from "./sharedClient.js";
import storage from "../Application/Services/storage.js";
import types from "./storeTypes.js";

const
    storedLanguages = storage.get(types.LANGUAGES),
    state = {
        [types.LANGUAGES]: Object.freeze(storedLanguages || null),
    },
    mutations = {
        [types.LANGUAGES](state, languages) {
            storage.set(types.LANGUAGES, languages);

            state[types.LANGUAGES] = languages;
        },
    },
    getters = {
        /**
         * Return the available languages.
         * @param {object} state
         * @return {object}
         */
        languages: state => state[types.LANGUAGES],
    },
    helpers = {
        /**
         * Format the languages list to make it compatible with Vuetify `v-select`.
         * @param {object} list
         * @return {*}
         */
        formatLanguages(list) {
            const keys = Object.keys(list);

            keys.forEach(key => {
                list[key] = {
                    value: key,
                    title: list[key],
                };
            });

            return list;
        }
    },
    actions = {
        /**
         * Fetch the language list from the server.
         * @see storage/app/data/languages.json
         * @param {function} commit
         * @param {function} getters
         * @param {boolean} asObject
         * @return Promise<object|array>
         */
        fetchLanguages({commit, getters}) {
            const languages = getters.languages;

            if (languages) {
                return Promise.resolve(languages);
            }

            return sharedClient
                .fetchLanguages()
                .then(languages => {
                    languages = Object.freeze(helpers.formatLanguages(languages));

                    commit(types.LANGUAGES, languages);

                    return languages;
                });
        }
    };

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
