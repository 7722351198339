<template>
    <div class="view-dashboard">
        <generic-view-title title="Dashboard"/>
    </div>
</template>

<script>
import GenericViewTitle from "../../Shared/Components/GenericViewTitle.vue";

export default {
    components: {
        GenericViewTitle
    },
}
</script>
