<template>
    <div class="view-401">
        Not authorized
    </div>
</template>

<script>
export default {
    components: {
    },
}
</script>
