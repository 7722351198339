<template>
    <v-app-bar :elevation="0" height="80">
        <template v-slot:prepend>
            <v-app-bar-nav-icon @click.stop="toggleMenu"
                                icon="spx:menu"
                                variant="flat"
                                size="x-small"
                                color="secondary"/>

            <toast/>
        </template>

        <template v-slot:append>
            <v-menu :close-on-content-click="false">
                <template v-slot:activator="{ props }">
                    <v-btn class="text-tertiary" color="lightTertiary" size="large" variant="flat" rounded="pill" v-bind="props">
                        <v-avatar size="30" class="mr-2 py-2">
                            <img :src="user.picture" :alt="user.name" class="w-100"/>
                        </v-avatar>
                        <v-icon icon="spx:IconSettings"/>
                    </v-btn>
                </template>
                <v-sheet width="330" elevation="12">
                    <UserMenu :user="user"/>
                </v-sheet>
            </v-menu>
        </template>
    </v-app-bar>
</template>

<script>
import UserMenu from "./UserMenu.vue";
import Toast from "../Plugins/toast/Toast.vue";

export default {
    components: {
        Toast,
        UserMenu,
    },
    props: ['user'],
    data: () => ({
        showingMenu: true,
    }),
    methods: {
        toggleMenu() {
            this.$emit('toggleMenu', this.showingMenu = !this.showingMenu);
        },
    }
}
</script>
