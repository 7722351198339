import Users from "../Auth/Views/Users.vue";
import UserEdit from "../Auth/Views/UserEdit.vue";
import Teams from "../Team/Views/Teams.vue";
import TeamEdit from "../Team/Views/TeamEdit.vue";

export default [
    /*  Super admin routes
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
    {
        path: '/admin/teams',
        component: Teams,
        meta: {
            middleware: ['auth', 'subscribed', 'can:manage_teams']
        },
    },
    {
        path: '/admin/teams/create',
        component: TeamEdit,
        meta: {
            middleware: ['auth', 'subscribed', 'can:manage_teams']
        },
    },
    {
        path: '/admin/teams/:id',
        component: TeamEdit,
        meta: {
            middleware: ['auth', 'subscribed', 'can:manage_teams']
        },
    },
    {
        path: '/admin/users',
        component: Users,
        meta: {
            middleware: ['auth', 'subscribed', 'can:manage_teams']
        },
    },
    {
        path: '/admin/users/create',
        component: UserEdit,
        meta: {
            middleware: ['auth', 'subscribed', 'can:manage_teams']
        },
    },
    {
        path: '/admin/users/:id',
        component: UserEdit,
        meta: {
            middleware: ['auth', 'subscribed', 'can:manage_teams']
        },
    },
];
