<template>
    <v-layout :class="(user ? '' : 'guest')">
        <Menu v-if="user" :show="showingMenu" :user="user"/>
        <Header v-if="!!user" :user="user" @toggleMenu="state => showingMenu = state"/>
        <v-main>
            <div :class="(user ? 'pr-3' : 'px-4 pt-4')">
                <v-container fluid class="view-wrap bg-lightTertiary">
                    <router-view class="view"></router-view>
                </v-container>
            </div>
            <Footer/>
        </v-main>
    </v-layout>
</template>

<script>
    import Footer from "../Components/Footer.vue";
    import Header from "../Components/Header.vue";
    import Menu from "../Components/Menu.vue";

    export default {
        components: {
            Menu,
            Header,
            Footer,
        },
        data: () => ({
            showingMenu: true,
        }),
        computed: {
            user: function () {
                return this.$store.getters['auth/user']
            }
        }
    }
</script>
