import "./Application/Services/axios.js";
import "../css/app.scss";
import { createApp } from "vue";
import App from "./Application/Views/Layout.vue";
import store from "./Application/store.js";
import router from "./Application/Router/";
import vuetify from "./Application/vuetify.js";
import notify from "./Application/Plugins/toast/index.js";
import sentry from "./Application/Services/sentry.js";
import constants from "./Application/constants.js";

const app = createApp(App);

if (constants.app.ENV === 'production') {
    sentry(app, router);
}

app.use(store)
    .use(vuetify)
    .use(router)
    .use(notify);

app.mount('#app');
