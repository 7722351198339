import axios from "axios";
import axiosConfig from "../Application/Services/axios.js";
import store from "../Application/store.js";

const authClient = axios.create();

// Add the current team ID to all requests from this client
authClient.interceptors.request.use(...axiosConfig.teamIdInterceptor);

/** Axios response interceptor. */
authClient.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && [401, 419].includes(error.response.status) && store.getters['auth/logged']) {
            store.dispatch('auth/logout');
        }
        return Promise.reject(error);
    }
);

export default {
    /**
     * Login on the server.
     * @param {string} username
     * @param {string} password
     * @returns {Promise<object>}
     */
    async login(username, password) {
        await authClient.get('/csrf-cookie');

        return authClient.post('/auth/login', {email: username, password: password});
    },
    /**
     * Log the current user out on the server.
     * @returns {*}
     */
    logout() {
        return authClient.post('/auth/logout');
    },
    /**
     * Fetch the authenticated user from the server.
     * @returns {object}
     */
    getAuthUser() {
        return authClient.get('/api/v1/user').then(response => response.data.data);
    },
    async forgotPassword(payload) {
        await authClient.get('/csrf-cookie');
        return authClient.post('/auth/forgot-password', payload);
    },
    async resetPassword(payload) {
        await authClient.get('/csrf-cookie');
        return authClient.post('/auth/reset-password', payload);
    },
    updatePassword(payload) {
        return authClient.put('/auth/user/password', payload);
    },
    sendVerification(payload) {
        return authClient.post('/auth/email/verification-notification', payload);
    },
};
